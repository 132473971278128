import React, { useState, useEffect, useCallback } from 'react';
import Container from '../__Container';
import styled from 'styled-components';
import { createForm } from 'rc-form';
import 'url-search-params-polyfill';
import Cascaders from './__Cascader';
import Toast from './__Toast';
import GlobalStyle from './__Global.style';
import Logo from 'imgs/1d1d/m/logo.png';

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  .logo {
    width: 1.78rem;
    height: 1.78rem;
    margin: 1rem 0;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 0.3rem 0.03px rgba(143, 58, 7, 0.09);
    img {
      width: 1.16rem;
      height: 0.51rem;
    }
  }
  form {
    button[type='submit'] {
      cursor: pointer;
      font-size: 0.3rem;
      width: 5.42rem;
      height: 0.79rem;
      background: #ff7c34;
      color: #fff;
      border: none;
      outline: none;
      border-radius: 0.2rem;
      margin-bottom: 0.97rem;
    }
    .input {
      width: 5.42rem;
      height: 0.9rem;
      margin-bottom: 0.4rem;
      border: 1px solid #ccc;
      font-size: 0.22rem;
      display: flex;
      align-items: center;
      border-radius: 0.2rem;
      overflow: hidden;
      &.code {
        position: relative;
        .btn {
          position: absolute;
          right: 0.15rem;
          top: 0.15rem;
          cursor: pointer;
          border: none;
          outline: none;
          background: ${props => (props.count == 0 ? '#ff7c34' : '#d6d6d6')};
          color: ${props => (props.count == 0 ? '#fff' : '#636363')};
          height: 0.6rem;
          line-height: 0.6rem;
          text-align: center;
          padding: 0 0.2rem;
          width: 1.6rem;
          border-radius: 0.1rem;
          font-size: 0.2rem;
        }
      }
      label {
        flex: 3;
        color: #000;
        margin-left: 0.28rem;
      }
      input {
        flex: 10;
        height: 100%;
        border: none;
        &:placeholder {
          color: #9f9f9f;
        }
      }
    }
  }
`;
// https://api.1d1d100.com/offical/post_mess
// https://api.1d1d100.com/offical/provice
// https://api.1d1d100.com/weixin/common/smssend/18201385843
function Apply({ form }) {
  const { getFieldProps } = form;
  const [mobile, setMobile] = useState('');
  const [count, setCount] = useState(0);
  const [region, setRegion] = useState([]);
  const [address, setAddress] = useState('');
  const [toast, setToast] = useState('');
  const [value, setValue] = useState('');
  const startCountdown = () => {
    setCount(60);
  };
  useEffect(() => {
    let interval;
    // 开始倒计时
    if (count) {
      interval = setInterval(() => {
        setCount(preCount => {
          if (preCount <= 1) {
            setCount(0);
            clearInterval(interval);
          } else {
            return preCount - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [count]);

  const handleCodeClick = async () => {
    // startCountdown();
    form.validateFields(async (err, values) => {
      if (values.mobile) {
        const resp = await fetch(`https://api.1d1d100.com/weixin/common/smssend`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ...values })
        });
        const { code } = await resp.json();
        if (code == 0) {
          startCountdown();
        } else {
          alert('验证码发送失败');
        }
      } else {
        setToast('请填写手机号');
      }
    });
  };
  useEffect(() => {
    const M = new URLSearchParams(window.location.search).get('mobile') || '';
    setMobile(M);
  }, []);

  //获取地区数据
  const getRegion = useCallback(async () => {
    const resp = await fetch(`https://api.1d1d100.com/offical/provice`);
    const { data } = await resp.json();
    const region = data.map(item => ({
      value: item.id,
      label: item.name,
      type: item.type,
      isLeaf: false
    }));
    setRegion(region);
  }, []);
  useEffect(() => {
    getRegion();
  }, [getRegion]);

  const handleSubmit = evt => {
    evt.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        if (!address) {
          setToast('请选择所在地区');
          return;
        }
        const resp = await fetch(`https://api.1d1d100.com/offical/post_mess`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ...values, ...address })
        });
        const { code, msg } = await resp.json();
        if (code == 0) {
          setToast('申请成功');
          form.resetFields();
          setValue('');
        } else {
          setToast(msg);
        }
      } else {
        const msg = err[Object.keys(err)[0]].errors[0].message;
        setToast(msg);
      }
    });
  };
  const checkRegion = data => {
    setRegion(data);
  };
  const checkValue = data => {
    setValue(data.address);
    setAddress(data);
  };
  const remove = () => {
    setToast('');
  };
  return (
    <Container>
      <GlobalStyle />
      {toast && <Toast text={toast} remove={remove} />}
      <Wrapper count={count}>
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input">
            <label htmlFor="mobile">手 机 号</label>
            <input
              type="tel"
              maxLength={11}
              placeholder="请输入手机号"
              {...getFieldProps('mobile', {
                rules: [
                  {
                    required: true,
                    message: '请输入手机号'
                  },
                  {
                    pattern: /^[1][0-9][0-9]{9}$/,
                    message: '请输入正确的手机号'
                  }
                ],
                initialValue: mobile
              })}
            />
          </div>
          <div className="input code">
            <label htmlFor="code">验 证 码</label>
            <input
              type="text"
              maxLength={6}
              placeholder="请输入验证码"
              {...getFieldProps('code', {
                rules: [{ required: true, message: '请输入验证码' }],
                initialValue: ''
              })}
            />
            <div className="btn" onClick={handleCodeClick}>
              {count > 0 ? count : `获取验证码`}
            </div>
          </div>
          <div className="input">
            <label htmlFor="schoolName">园所名称</label>
            <input
              maxLength={20}
              type="text"
              placeholder="请输入园所名称"
              {...getFieldProps('schoolName', {
                rules: [{ required: true, message: '请输入园所名称' }],
                initialValue: ''
              })}
            />
          </div>
          <div className="input addr">
            <label htmlFor="addr">所在地区</label>
            <Cascaders
              options={region}
              placeholder="请选择所在地区"
              checkRegion={checkRegion}
              checkValue={checkValue}
              value={value}
            />
          </div>
          <button type="submit">立即申请</button>
        </form>
      </Wrapper>
    </Container>
  );
}

export default createForm()(Apply);
