import React from 'react';
import styled from 'styled-components';
import Cascader from 'rc-cascader';
import 'RootCommon/cascader.css';
const Wrapper = styled.div`
  flex: 10;
`;
const Loading = styled.i`
  position: absolute;
  right: 12px;
  color: #aaa;
  animation: loadingCircle 1s infinite linear;
  @keyframes loadingCircle {
    100% {
      transform: rotate(1turn);
    }
  }
`;
const loadingPath =
  'M168 504.2c1-43.7 10-86.1 26.9-126 17.3-41 42.1-77.7 73.7-109.4S337 212.3 378 195c42.4-17.9 87.4-27 133.9-27s91.5 9.1 133.8 27A341.5 341.5 0 0 1 755 268.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 0 0 3 14.1l175.7 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c0-6.7-7.7-10.5-12.9-6.3l-56.4 44.1C765.8 155.1 646.2 92 511.8 92 282.7 92 96.3 275.6 92 503.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8zm756 7.8h-60c-4.4 0-7.9 3.5-8 7.8-1 43.7-10 86.1-26.9 126-17.3 41-42.1 77.8-73.7 109.4A342.45 342.45 0 0 1 512.1 856a342.24 342.24 0 0 1-243.2-100.8c-9.9-9.9-19.2-20.4-27.8-31.4l60.2-47a8 8 0 0 0-3-14.1l-175.7-43c-5-1.2-9.9 2.6-9.9 7.7l-.7 181c0 6.7 7.7 10.5 12.9 6.3l56.4-44.1C258.2 868.9 377.8 932 512.2 932c229.2 0 415.5-183.7 419.8-411.8a8 8 0 0 0-8-8.2z';
const loadingIcon = (
  <Loading>
    <svg
      viewBox="0 0 1024 1024"
      width="1em"
      height="1em"
      fill="currentColor"
      style={{
        verticalAlign: '-.125em',
        margin: 'auto'
      }}
    >
      <path d={loadingPath} />
    </svg>
  </Loading>
);
const CommonCascader = props => {
  const { placeholder, options = [], checkRegion, checkValue, value } = props;
  const change = (value, selectedOptions) => {
    let obj = { address: selectedOptions.map(item => item.label).join(' ') };
    selectedOptions.forEach(item => {
      if (item.type == 1) {
        obj.proviceId = item.value;
      } else if (item.type == 2) {
        obj.cityId = item.value;
      } else {
        obj.areaId = item.value;
      }
    });
    checkValue(obj);
  };
  const loadDatas = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    const { value, type } = targetOption;
    if (type == 3) {
      return;
    }
    targetOption.loading = true;
    const resp = await fetch(`https://api.1d1d100.com/offical/city/${value}`);
    const { data } = await resp.json();
    const list = data.map(item => ({
      value: item.id,
      label: item.name,
      type: item.type,
      isLeaf: type == 2 ? true : false
    }));
    targetOption.loading = false;
    targetOption.children = list;
    checkRegion([...options]);
  };

  return (
    <Wrapper>
      <Cascader
        options={options}
        loadData={loadDatas}
        onChange={change}
        changeOnSelect
        loadingIcon={loadingIcon}
        expandIcon={null}
      >
        <input placeholder={placeholder} value={value} readOnly />
      </Cascader>
    </Wrapper>
  );
};
export default CommonCascader;
